import React from 'react'
import { useRoutes } from 'react-router-dom'
import About from './About'
import Hero from './Hero/Hero'
import Register from "./Register"

const Allroutes = () => {

    let element = useRoutes([
        {
            path: "/",
            element: <Hero />
        },
        {
            path: "/register",
            element: <Register />
        },
        {
            path: "/about",
            element: <About />
        },
    ])
  return element
}

export default Allroutes