import React ,{useState}from 'react'
import styled from 'styled-components'
import images from "./Assets/codelablogo.png"
import {GiHamburgerMenu} from "react-icons/gi"
import { NavLink } from 'react-router-dom'

const Header = () => {
    const [show, setShow] = useState<Boolean>(false)

    const Toogle = () => {
        setShow(!show)
    }
  return (
    <Container>
        <Wrapper>
            <Logo>
            <Images src={images} />
        </Logo>
        <Nav>
            <Text to="/register"><h3>Register</h3></Text>
            <Text to="/about"><h3>About the Developer</h3></Text>
        </Nav>
        <Icons onClick={Toogle}><GiHamburgerMenu /></Icons>
        {show ? (
            <Card>
            <Text2 to="/register"><h3>Register</h3></Text2>
            <Text2 to="/about"><h3>About the Developer</h3></Text2>
        </Card>
        ) : null}
        </Wrapper>
    </Container>
  )
}

export default Header
const Card = styled.div`
    width: 230px;
    height: 160px;
    border-radius: 5px;
    background-color: white;
    position: absolute;
    right: 0;
    top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const Icons = styled.div`
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    /* margin-right: 40px; */
    cursor: pointer;
    color: white;
    @media screen and (max-width: 616px){
        display: flex;
    }
`
const Text2 = styled(NavLink)`
    cursor: pointer;
    transition: all 350ms;
    margin-left: 22px;
    color: black;
    text-decoration: none;
`

const Text = styled(NavLink)`
    cursor: pointer;
    transition: all 350ms;
    margin-left: 22px;
    text-decoration: none;
    h3{
        color: white;
    }
`

const Nav = styled.div`
    display: flex;
    align-items: center;
    color: white;
    @media screen and (max-width: 616px) {
        display: none;
    }
`

const Wrapper = styled.div`
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
`

const Images = styled.img`
    /* width: 75%; */
height: 40px;
@media screen and (max-width: 616px){
        height: 30px;
    }
`

const Logo = styled.div`
    width: 200px;
    display: flex;
    height: 100%;
    align-items: center;
`

const Container = styled.div`
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: none;
    /* position: fixed; */
    z-index: 435337;
    /* background-color: rgba(0, 44, 81, 0.3); */
    @media screen and (max-width: 330px){
        /* width: 100%; */
        /* background-color: red; */
    }
`