import React from 'react'
import styled from 'styled-components'
import images from "./Assets/techme.png"
import images2 from "./Assets/Godwin.png"
import images3 from "./Assets/mongo.png"
import images4 from "./Assets/html.png"
import images5 from "./Assets/javascript.png"
import images6 from "./Assets/reacticon.png"
import images7 from "./Assets/typescript.png"
import images8 from "./Assets/express.png"
import {BsFacebook, BsInstagram, BsTwitter, BsLinkedin, BsFillArrowLeftCircleFill} from "react-icons/bs"

const About = () => {
  return (
    <Container>
        <Top>Okwoli Godwin</Top>
        <P>Hello everyone, My name is Okwoli Godwin. i'm currently a student <br /> of CodeLab,
        going through my trainning session of becoming a fullstack web developer</P>

        <Q>I have a diverse set of skills, ranging from Ui/Ux design, to HTML + <br />
        CSS + Javascript, all the way to React Js, Node Js, Typescript MongoDB, and Algorithnm</Q>
        <Circle><Images src={images2}/></Circle>
        <Iconhold>
            <Icon><BsFacebook /></Icon>
            <Icon2><BsInstagram /></Icon2>
            <Icon3><BsTwitter /></Icon3>
            <Icon4><BsLinkedin /></Icon4>
        </Iconhold>
        <Line></Line>
        <Stackhold>
            <Text>Stacks:</Text>
            <Image src={images3} />
            <Html src={images4} />
            <Java src={images5} />
            <Rea src={images6} />
            <Type src={images7} />
            <Type src={images8} />
        </Stackhold>
    </Container>
  )
}

export default About
const Type = styled.img`
    height: 50px;
    margin-left: 10px;
`
const Rea = styled.img`
    height: 50px;
    margin-left: 10px;
`
const Java = styled.img`
    height: 50px;
    margin-left: 10px;
`
const Html = styled.img`
    height: 50px;
    margin-left: 10px;
`
const Image = styled.img`
    height: 50px;
    margin-left: 10px;
`
const Text = styled.h3`
    color: white;
`
const Stackhold = styled.div`
    width: 400px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-wrap: wrap;
    @media screen and (max-width: 396px){
        width: 350px;
    }
`
const Line = styled.div`
    width: 300px;
    height: 1px;
    background-color: gray;
    margin-top: 30px;
`
const Icon4 = styled.div`
    font-size: 20px;
    cursor: pointer;
    color: #228DDA;
`
const Icon3 = styled.div`
    font-size: 20px;
    cursor: pointer;
    color: #1C9CEA;
`
const Icon2 = styled.div`
    font-size: 20px;
    cursor: pointer;
    color: #7F3DB4;
`
const Icon = styled.div`
    font-size: 20px;
    cursor: pointer;
    color: #1773EA;
`
const Iconhold = styled.div`
    width: 130px;
    display: flex;
    justify-content: center;
    justify-content: space-between;
    margin-top: 10px;
`
const Images = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`
const Circle = styled.div`
    width: 110px;
    height: 110px;
    overflow: hidden;
    border-radius: 100px;
    background-color: white;
    margin-top: 40px;
`
const Q = styled.div`
    text-align: center;
    color: white;
    font-size: 18px;
    @media screen and (max-width: 660px){
        width: 65%;
    }
`
const P = styled.div`
    padding-bottom: 20px;
    text-align: center;
    font-size: 18px;
    color: white;
    flex-wrap: wrap;
    @media screen and (max-width: 660px){
        width: 65%;
    }
`
const Top = styled.h1`
    color: white;
    font-size: 40px;
`
const Card = styled.div`
    width: 400px;
    height: 520px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10pxs;
`

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    background-color: red;
    background-image: url(${images});
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 63px;
    padding-bottom: 70px;
    background-size: cover;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`